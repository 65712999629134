// 确权列表
const getRightList = `${API_CONFIG.butlerBaseURL}landInfo/getLandUserListByPage`
// 确权详情
const getRightInfo = `${API_CONFIG.butlerBaseURL}landInfo/detailsLandUser`
// 获取土地列表
const getLandList = `${API_CONFIG.butlerBaseURL}landInfo/getLandInfoListByPage`
// 编辑确权信息
const editRightInfo = `${API_CONFIG.butlerBaseURL}landInfo/editLandUser`
// 新增确权信息
const addRightInfo = `${API_CONFIG.butlerBaseURL}landInfo/addLandUser`
// 导出
const exportListUrl = `${API_CONFIG.butlerBaseURL}landInfo/exportLandUserList`
// 获取用户列表
const getOwnerListURL = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`
// 根据用户id查身份证号
const getIdNum = `${API_CONFIG.butlerBaseURL}landInfo/landUserByCard`

export {
  getRightList,
  getLandList,
  getRightInfo,
  editRightInfo,
  addRightInfo,
  exportListUrl,
  getOwnerListURL,
  getIdNum
}
