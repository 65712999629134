<template>
  <div class="rightConfirManageList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <!-- <v-button text="导入" permission="import" @click="landImport"></v-button> -->
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 :width="200" label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input :width="200" label="户主" v-model="searchParams.userName"></v-input>
        <v-input :width="200" label="承包家庭" v-model="searchParams.houseAddress"></v-input>
        <v-input :width="200" label="合同代码" v-model="searchParams.contractCode"></v-input>
        <!-- <v-input label="承包总面积" v-model="searchParams.userName"></v-input> -->
        <v-input :width="200" label="承包总地块" v-model="searchParams.contractNum"></v-input>
        <v-datepicker label="创建时间" :startTime.sync="searchParams.intimeStart" :endTime.sync="searchParams.intimeEnd"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" permission="update" @click="check(scope.row)"></v-button>
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  exportListUrl,

  getRightList
} from './api'
import {
  landType,
  RightConfirStatus,
  contractMap
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'OwnerCarList',
  data () {
    return {
      landType,
      RightConfirStatus,
      contractMap,

      searchUrl: getRightList,
      exportUrl: exportListUrl,
      communityParams,
      searchParams: {
        communityId: '',
        userName: '',
        houseAddress: '',
        contractCode: '',
        contractNum: '',
        intimeStart: '',
        intimeEnd: ''
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'userName',
          label: '户主'
        },
        // {
        //   prop: 'cardNum',
        //   label: '身份证号码'
        // },
        {
          prop: 'mobileNum',
          label: '手机号码'
        },
        {
          prop: 'houseAddress',
          label: '承包家庭'
        },
        {
          prop: 'contractor',
          label: '发包方'
        },
        {
          prop: 'contractorType',
          label: '承包方式',
          formatter: row => contractMap[row.contractorType]
        },
        {
          prop: 'contractCode',
          label: '合同代码'
        },
        {
          prop: 'time',
          label: '承包期限',
          formatter: row => {
            return `${row.contractBeginTime} - ${row.contractEndTime}`
          }
        },
        {
          prop: 'madeByArea',
          label: '承包总面积',
          formatter: row => {
            const { contractAreaHectare, contractAreaLi, contractAreaMu, contractAreaHao, contractAreaFen} = row
            const Hectare = Number(contractAreaHectare) !== 0 ? `${Number(contractAreaHectare)}公顷` : ''
            const Li = Number(contractAreaLi) !== 0 ? `${Number(contractAreaLi)}厘` : ''
            const Mu = Number(contractAreaMu) !== 0 ? `${Number(contractAreaMu)}亩` : ''
            const Hao = Number(contractAreaHao) !== 0 ? `${Number(contractAreaHao)}毫` : ''
            const Fen = Number(contractAreaFen) !== 0 ? `${Number(contractAreaFen)}分` : ''
            return Hectare + Mu + Fen + Li + Hao
          }
        },
        {
          prop: 'contractNum',
          label: '承包总块数',
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    // 新增
    create () {
      this.$router.push({
        name: 'rightConfirManageForm',
        query: {
          type: 'add'
        }
      })
    },
    // 编辑
    edit (row) {
      this.$router.push({
        name: 'rightConfirManageForm',
        query: {
          id: row.id,
          houseId: row.houseId,
          type: 'edit'
        }
      })
    },
    // 查看
    check (row) {
      this.$router.push({
        name: 'rightConfirManageForm',
        query: {
          id: row.id,
          type: 'check'
        }
      })
    },
    landImport () {
      this.$router.push({
        name: 'landManageImport',
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rightConfirManageList-wrapper {
  width: 100%;
  ::v-deep .v-datepicker-container {
    width: auto !important;
  }
}

</style>
